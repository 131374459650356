<template>
  <layout-default>
    <v-layout wrap>
      <v-flex xs12 md12>
        <v-btn
          v-if="isAdmin"
          color="info"
          :loading="loading"
          @click="syncReleases"
          >Sync releases
        </v-btn>
        <v-btn class="pull-right" @click="addRelease">Create release</v-btn>
        <br/>
        <v-card class="mt-4">
          <v-card-title>
            Releases
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-if="releases"
              :options.sync="options"
              :loading="$apollo.loading"
              :headers="headers"
              :items="filteredReleases"
              :search="search"
          >
            <template v-slot:item="{ item }">
              <tr :active="item.selected">
                <td :class="itemClass(item)">
                  <h3>{{ item.name }}</h3>
                </td>
                <td :class="itemClass(item)">
                  <h3>{{ item.description || "-" }}</h3>
                </td>
                <td :class="itemClass(item)">
                  {{ item.author.name }}
                </td>
                <td :class="itemClass(item)">
                  {{ item.createdAt | moment("DD-MM-YYYY H:mm:ss") }}
                </td>
                <td :class="itemClass(item)">{{ item.status }}</td>
                <td class="text-xs-right text-md-right" :class="itemClass(item)">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item>
                          <v-list-item-title @click="showDetails(item.id)">
                            Inspect
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="dialog" width="700">
        <release-details-dialog
            v-if="selectedRelease"
            :release="selectedRelease"
            @close="dialog = false"
        ></release-details-dialog>
      </v-dialog>
    </v-layout>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layouts/Default.vue";
import LIST_RELEASES from "@/graphql/query/ReleasesList.gql";
import SYNC_RELEASES from "@/graphql/mutation/SyncReleases.gql";
import ReleaseDetailsDialog from "@/components/blocks/ReleaseDetailsDialog.vue";
import { isInRole } from '../../../services/authService';

export default {
  name: "listReleases",
  components: {
    ReleaseDetailsDialog,
    LayoutDefault,
  },
  data: () => ({
    releases: [],
    options: {
      sortBy: ["createdAt"],
      descending: true,
      itemsPerPage: 25,
    },
    filters: {},
    selectedRelease: null,
    loading: false,
    error: String,
    drawer: null,
    search: null,
    headers: [
      {text: "Name", align: "left", value: "name"},
      {text: "Description", align: "left", value: "description"},
      {text: "Author", align: "left", value: "author.name"},
      {text: "Created at", align: "left", value: "createdAt"},
      {text: "Status", align: "left", value: "status"},
      {text: "Actions", align: "right", value: "actions"},
    ],
  }),
  computed: {
    isAdmin() {
      return isInRole('admin');
    },
    dialog: {
      get() {
        return this.selectedRelease;
      },
      set(value) {
        if (value === false) {
          this.selectedRelease = null;
        }
      },
    },
    filteredReleases() {
      return this.releases.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
  },
  watch: {
    releases() {
      if (!this.selectedRelease) {
        return;
      }
      this.selectedRelease = this.findRelease(this.selectedRelease.id);
    },
  },
  methods: {
    itemClass: (item) => {
      if (item.status === "GREEN") {
        return "green";
      }
      if (item.status === "BLUE") {
        return "blue darker";
      }
      return "gray";
    },
    addRelease() {
      this.$router.push({name: "releaseAdd"});
    },
    findRelease(id) {
      return this.releases.find((r) => r.id === id);
    },
    showDetails(id) {
      this.selectedRelease = this.findRelease(id);
    },
    syncReleases() {
      this.loading = true;
      this.$apollo
          .mutate({
            mutation: SYNC_RELEASES,
            update: (store, {data: {releases}}) => {
            let data = store.readQuery({ query: LIST_RELEASES });
            data.releases = releases;
              store.writeQuery({query: LIST_RELEASES, data});
            this.loading = false;
            },
          })
          .catch((error) => {
            this.loading = false;
            this.lastError = error;
          });
    },
  },
  apollo: {
    releases: {
      query: LIST_RELEASES,
      loadingKey: "loading",
      error(error) {
        this.error = error;
      },
    },
  },
};
</script>
