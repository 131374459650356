<template>
  <v-card>
    <v-card-title :class="`headline ${itemClass(release)}`" primary-title>
      Release {{ release.name }}
    </v-card-title>
    <v-card-text>
      Author
      <h3>{{ release.author.name }}</h3>
      <br />
      Created
      <h3>{{ release.createdAt | moment("DD-MM-YYYY H:mm:ss") }}</h3>
      <br />
      Updated
      <h3>{{ release.updatedAt | moment("DD-MM-YYYY H:mm:ss") }}</h3>
      <br />
      Status
      <h3>{{ release.status }}</h3>
      <br />
      Description
      <h3>{{ release.description || "-" }}</h3>
      <br />
      Log
      <v-list dense v-for="log in release.logs" :key="log.id">
        <v-chip>
          <v-icon small>fa-clock</v-icon>
          &nbsp;{{ log.timestamp | moment("DD-MM-YYYY H:mm:ss") }}
        </v-chip>
        <v-chip>{{ log.actor.name }}</v-chip>
        <v-chip>{{ log.action }}</v-chip>
      </v-list>
      <br />

      <!-- <v-divider class="mt-1 mb-1"></v-divider>
      <v-btn @click="copy">Show specs / copy to clipboard</v-btn>
      <v-btn @click="copyRelease" color="green">Copy release</v-btn> 
      <v-divider class="mt-1 mb-3"></v-divider>
      -->

      Services
      <v-list dense v-for="tag in release.tags" :key="tag.id">
        <v-chip
          >{{ tag.service.name }} ({{
            tag.lastUpdated | moment("DD-MM-YYYY H:mm:ss")
          }})</v-chip
        >
        <h3 style="float: right">{{ tag.name }}</h3>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="$emit('close')">Close</v-btn>
      <v-btn @click="copyRelease(release.id)">Copy</v-btn>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on">
            Compare with
          </v-btn>
        </template>
        <v-list>
          <template v-for="item in allReleases">
            <v-list-item :key="item.id">
              <v-list-item-title @click="compareReleases(item.id)"
                >{{ item.label }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-btn
        v-if="release.status === 'BLUE'"
        color="green"
        @click="switchActive(release.id)"
        :loading="loadingSetGreen"
      >
        Switch to GREEN
      </v-btn>
      <v-btn
        v-if="release.status === 'GRAY'"
        color="blue"
        @click="deploy(release.id)"
        :loading="loadingDeploy"
      >
        Deploy
      </v-btn>
      <v-btn
        v-if="release.status === 'BLUE'"
        color="gray"
        @click="undeploy(release.id)"
        :loading="loadingUndeploy"
      >
        Undeploy
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DEPLOY_RELEASE from "@/graphql/mutation/DeployReleaseMutation.gql";
import UNDEPLOY_RELEASE from "@/graphql/mutation/UndeployReleaseMutation.gql";
import SWITCH_ACTIVE from "@/graphql/mutation/SwitchActiveReleaseMutation.gql";
import LIST_RELEASES from "@/graphql/query/ReleasesList.gql";
import { debug, handleGqlError } from "@/helpers/debug.ts";

export default {
  name: "releaseDetailsDialog",
  props: {
    release: Object,
  },

  data: () => ({
    loading: false,
    loadingDeploy: false,
    loadingUndeploy: false,
    loadingSetGreen: false,
  }),
  computed: {
    allReleases() {
      let arr = [];
      for (let release of this.releases) {
        arr.push({ label: release.name, id: release.id });
      }
      return arr;
    },
    servicesSpecs() {
      let spec = { release: this.release.name, versions: {} };
      for (let tag of this.release.tags) {
        spec.versions[tag.service.name] = tag.name;
      }
      return JSON.stringify(spec, null, 2);
    },
    servicesSpecsAsQueryStr() {
      let spec = {};
      for (let tag of this.release.tags) {
        spec[tag.service.name] = tag.name;
      }
      return spec;
    },
  },
  methods: {
    copy() {
      alert("Specs: " + this.servicesSpecs);
    },
    copyRelease() {
      this.$router.push({
        name: "releaseAdd",
        query: this.servicesSpecsAsQueryStr,
      });
    },
    compareReleases(targetId) {
      this.$router.push({
        name: "releaseDiff",
        params: { baseId: this.release.id, targetId },
      });
    },
    itemClass: (item) => {
      if (item.status === "GREEN") {
        return "green";
      }
      if (item.status === "BLUE") {
        return "blue darker";
      }
      return "gray";
    },
    getRawTags(tags) {
      let result = [];
      for (let tag of tags) {
        result.push(tag.name);
      }
      return result;
    },
    shouldDisplay(name) {
      return typeof this.model[name] !== "undefined";
    },
    refreshReleases() {
      debug("refreshReleases");
      this.$apollo.queries.releases.refetch();
    },
    async switchActive(id) {
      let res = await this.$confirm(
        `You will switch active release to ${this.release.name}.`,
        { title: "Release set GREEN" }
      );
      if (!res) {
        return;
      }
      this.loadingSetGreen = true;
      this.$apollo
        .mutate({
          mutation: SWITCH_ACTIVE,
          variables: {
            releaseId: id,
          },
          update: (store, { data: releases }) => {
            this.loadingSetGreen = false;
            store.writeQuery({ query: LIST_RELEASES, data: releases });
            this.$router.push({ name: "releasesList" });
          },
        })
        .catch((e) => {
          handleGqlError(e);
          this.loadingSetGreen = false;
        });
    },
    async deploy(id) {
      let res = await this.$confirm(
        `You will deploy release ${this.release.name} to k8s cluster.`,
        { title: "Release deploy" }
      );
      if (!res) {
        return;
      }
      this.loadingDeploy = true;
      this.$apollo
        .mutate({
          mutation: DEPLOY_RELEASE,
          variables: {
            releaseId: id,
          },
          update: () => {
            this.loadingDeploy = false;
          },
        })
        .catch((e) => {
          handleGqlError(e);
          this.loadingDeploy = false;
        });
    },
    async undeploy(id) {
      let res = await this.$confirm(
        `You will undeploy release ${this.release.name} from k8s cluster.`,
        { title: "Release undeploy" }
      );
      if (!res) {
        return;
      }
      this.loadingUndeploy = true;
      this.$apollo
        .mutate({
          mutation: UNDEPLOY_RELEASE,
          variables: {
            releaseId: id,
          },
          update: () => {
            this.loadingUndeploy = false;
          },
        })
        .catch((e) => {
          handleGqlError(e);
          this.loadingUndeploy = false;
        });
    },
    showDetails(id) {
      this.release = this.findRelease(id);
    },
    columnValueList(val) {
      return this.releases.map((d) => d[val]);
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
  },
  apollo: {
    releases: {
      query: LIST_RELEASES,
      loadingKey: "loading",
      error(error) {
        this.error = error;
      },
    },
  },
};
</script>
